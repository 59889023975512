import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout/layout';

import { Fullscreen } from '../components/fullscreen/fullscreen';
import { Background } from '../components/background/background';
import {
    BACKGROUND_COLORS,
    COMPANY_COLORS,
    PRIMARY_SUBTITLE_COLOR,
    PRIMARY_TEXT_COLOR,
    PRIMARY_TITLE_COLOR,
    TAKK_SECTIONS,
    TEXT_FONT,
    TITLE_FONT,
} from '../constants';

import cover from '../../static/images/case-studies/cover.png';
import mbank from '../../static/images/case-studies/mBank.png';
import banknet from '../../static/images/case-studies/nkbm.png';
import cbdc from '../../static/images/case-studies/CBDC.png';
import otp from '../../static/images/case-studies/OTP.png';
import takk from '../../static/images/case-studies/TAKK.png';
import libraro from '../../static/images/case-studies/Libraro.png';
import ecotrail from '../../static/images/case-studies/Ecotrail2.png';
import lrsystem from '../../static/images/case-studies/LRSystem.png';
import rockscrollable from '../../static/images/case-studies/scrollable_image.png';
import rockellipseleft from '../../static/images/case-studies/RockEllipseLeft.png';
import rockellipseright from '../../static/images/case-studies/RockEllipseRight.png';
import complexellipseright1 from '../../static/images/case-studies/ComplexEllipseRight1.png';
import complexellipseright2 from '../../static/images/case-studies/ComplexEllipseRight2.png';
import complexellipseleft1 from '../../static/images/case-studies/ComplexEllipseLeft1.png';
import complexellipseleft2 from '../../static/images/case-studies/ComplexEllipseLeft2.png';
import complexellipseleft3 from '../../static/images/case-studies/ComplexEllipseLeft3.png';
import nkbmmobile from '../../static/images/case-studies/nkbmmobile.png';
import otpmobile from '../../static/images/case-studies/OTPMobile.png';
import libraromobile from '../../static/images/case-studies/Libraromobile.png';
import cbdcmobile from '../../static/images/case-studies/CBDCmobile.png';
import complex1 from '../../static/images/case-studies/complex_scroll_1.png';
import complex2 from '../../static/images/case-studies/complex_scroll_2.png';
import complex3 from '../../static/images/case-studies/complex_scroll_3.png';
import complex4 from '../../static/images/case-studies/complex_scroll_4.png';
import complex5 from '../../static/images/case-studies/complex_scroll_5.png';
import complex6 from '../../static/images/case-studies/complex_scroll_6.png';
import complex7 from '../../static/images/case-studies/complex_scroll_7.png';
import rockvideo from '../../static/videos/rock_video.mp4';
import complexvideo from '../../static/videos/complex_video.mp4';
import ministrstvo from '../../static/images/case-studies/MinistrstvoGospodarstvo.png';
import euRazvoj from '../../static/images/case-studies/EURazvoj.png';
import spiritSlovenia from '../../static/images/case-studies/SpiritSlovenia.png';
import { Container } from 'reactstrap';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { MAX_PAGES } from '.';
import { Features } from '../components/study-features/study-features';
import { FeatureTags } from '../components/feature-tags/feature-tags';
import { HorizontalScroll } from '../components/horizontal-scroll/horizontal-scroll';
import { PhoneVideo } from '../components/phone-video/phone-video';
import { Ellipse } from '../components/ellipse/ellipse';
import { HorizontalScrollTitle } from '../components/horizontal-scroll-title/horizontal-scroll-title';
import { RockBackground } from '../components/rock-background/rock-background';
import { BackgroundPrint } from '../components/background-print/background-print';
import { YourFuture } from '../components/your-future/your-future';

const TitleContainer = styled(Container)`
    z-index: 1;
`;

const Title = styled.h1`
    font-family: ${TITLE_FONT};
    font-size: 102px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: -6.3px;
    color: ${PRIMARY_TEXT_COLOR};

    @media (max-width: 768px) {
        font-size: 68px;
        letter-spacing: -2px;
    }
`;

const Wrapper = styled.div`
    padding: 150px 0 100px 50px;
    display: flex;
    flex-direction: column;
    gap: 160px;
    align-items: center;
    background-color: ${BACKGROUND_COLORS.color_bg_light};
    overflow: hidden;

    @media (max-width: 1000px) {
        padding: 150px 0 100px 0;
    }

    @media (max-width: 768px) {
        padding: 64px 0 100px 0;
    }
`;

const CaseTitle = styled.h3`
    font-family: ${TITLE_FONT};
    word-break: break-all;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${PRIMARY_TITLE_COLOR};
    margin-bottom: 8px;

    @media (max-width: 1000px) {
        padding: 0 50px;
    }

    @media (max-width: 768px) {
        font-size: 40px;
        padding: 0 16px;
    }
`;

const CaseType = styled.h4`
    font-family: ${TITLE_FONT};
    font-size: 32px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    color: ${PRIMARY_TITLE_COLOR};
    margin-bottom: 12px;
    padding: 0;

    @media (max-width: 1000px) {
        display: none;
    }
`;

const CaseDescription = styled.p`
    font-family: ${TEXT_FONT};
    max-width: 512px;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 0.5px;
    color: ${PRIMARY_TITLE_COLOR};
    margin-top: 48px;
    margin-bottom: 77px;
    white-space: pre-line;

    @media (max-width: 1000px) {
        max-width: 100vw;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 50px;
    }

    @media (max-width: 768px) {
        padding: 0 16px;
    }
`;

const SeeMoreContainer = styled.div`
    a {
        color: ${PRIMARY_TEXT_COLOR};
        font-family: ${TITLE_FONT};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.1px;
    }

    a:hover {
        color: ${PRIMARY_SUBTITLE_COLOR};
    }
`;

const ClutchReviewContainer = styled.div`
    a {
        color: ${PRIMARY_TEXT_COLOR};
        font-family: ${TITLE_FONT};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.1px;
    }

    a:hover {
        color: ${PRIMARY_SUBTITLE_COLOR};
    }

    margin-bottom: 20px;
`;

const RowWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 170px;
    width: 100%;

    @media (max-width: 768px) {
        gap: 32px;
    }
`;

const Row = styled.div`
    display: flex;
    align-self: center;
    justify-content: space-between;
    text-align: justify;
    max-width: 1565px;
    width: 100%;
    z-index: 10;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const BigImage = styled.img`
    max-width: 1250px;
    max-height: 100%;
    @media (max-width: 1000px) {
        margin-left: 20px;
    }
`;

const TwoScreenImage = styled.img`
    max-width: 748px;
    max-height: 100%;
    margin-right: 30px;
    @media (max-width: 1000px) {
        margin-right: 0;
        width: 100%;
        padding: 0 30px;
    }
`;

const Column = styled.div`
    min-width: 579px;
    margin: auto 0;

    @media (max-width: 1000px) {
        min-width: unset;
    }
`;

const ImageColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    padding-right: 50px;
    @media (max-width: 1000px) {
        display: none;
    }
`;

const ImageColumnMobile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 60px 0;
    @media (min-width: 1000px) {
        display: none;
    }
`;

const BigImageColumn = styled.div`
    @media (max-width: 1000px) {
        display: none;
    }
`;

const Sponsors = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 579px;
    padding: 12px 21px;

    @media (max-width: 1000px) {
        margin-top: 48px;
        width: 100%;
        max-width: unset;
    }
`;

const SponsorsRow = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 1000px) {
        justify-content: center;
        gap: 40px;
    }

    @media (max-width: 600px) {
        align-items: center;
        flex-direction: column;
        gap: 40px;
    }
`;

const SponsorsSingleItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const SponsorImage = styled.img`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : '100%')};

    @media (max-width: 600px) {
        width: 272px;
        height: auto;
    }
`;

const ClutchName = styled.h2`
  font-family: ${TITLE_FONT};
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: ${props => props.inputColor || PRIMARY_TEXT_COLOR};
  z-index: 1;

  position: absolute;
  top: 0;
  left: 10px;
}
`;

export default () => {
    const { canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);
    return (
        <Layout showShadow={true} setCanScroll={setCanScroll} canScroll={canScroll}>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <Background bg={cover} design={'cover'} index={1} />
                <TitleContainer>
                    <Title>
                        Case <br /> studies.
                    </Title>
                </TitleContainer>
            </Fullscreen>
            <Wrapper>
                <Row id={'banknet'}>
                    <Column>
                        <CaseType>Case study</CaseType>
                        <CaseTitle>mBank@Net</CaseTitle>
                        <FeatureTags tags={['Android', 'IOS']} color={COMPANY_COLORS.nkbm} />
                        <ImageColumnMobile>
                            <Image src={mbank} alt={'mBank@Net mobile preview'} />
                        </ImageColumnMobile>
                        <CaseDescription>
                            The mBank@net mobile banking app is a central digital channel for Nova KBM’s customers, where users can perform
                            and monitor basic and complex services. These include easily transferring money between individuals by entering
                            the amount and sharing the connection via SMS, Viber, etc., making domestic and international payments,
                            exchanging to foreign currencies, paying and monitoring e-bills, accounts, card transactions, managing loans,
                            and much more. Additionally, it also allows opening up a bank account for new customers.
                        </CaseDescription>
                        <Features color={COMPANY_COLORS.nkbm} />
                    </Column>
                    <ImageColumn>
                        <Image src={mbank} alt={'mBank@Net preview'} />
                    </ImageColumn>
                </Row>
                <RowWrapper>
                    <BackgroundPrint />
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>Bank@Net</CaseTitle>
                            <FeatureTags tags={['Responsive Web App']} color={COMPANY_COLORS.nkbm} />
                            <ImageColumnMobile>
                                <Image src={nkbmmobile} alt={'Bank@Net mobile preview'} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                Bank@Net is a web-based banking platform for the second largest bank in Slovenia. It received an award for
                                the best online banking platform for its user-centered design that makes payments and personal finance
                                management easy and enjoyable.
                            </CaseDescription>
                            <Features color={COMPANY_COLORS.nkbm} />
                        </Column>
                        <BigImageColumn>
                            <BigImage src={banknet} alt={'Bank@Net preview'} />
                        </BigImageColumn>
                    </Row>
                </RowWrapper>
                <RowWrapper id={'rockwallet'}>
                    <Ellipse offset={50} image={rockellipseleft} top={20} size={70} />
                    <Ellipse right offset={50} image={rockellipseright} top={40} size={70} />
                    <RockBackground />
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>RockWallet</CaseTitle>
                            <FeatureTags tags={['Android', 'IOS', 'Admin Web App']} color={COMPANY_COLORS.rockwallet} />
                            <ImageColumnMobile>
                                <PhoneVideo video={rockvideo} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                RockWallet is a self-custody mobile crypto wallet for storing and managing digital assets. RockWallet is
                                designed to securely store, send and receive various digital currencies like Bitcoin, Ethereum, Litecoin and
                                many more. It offers buying, swapping and selling digital assets with payment methods such as Card and ACH.
                                Among latest security features such as biometrics and 2Fa app offers self onboarding with built in KYC and
                                AML process.
                            </CaseDescription>
                            <Features color={COMPANY_COLORS.rockwallet} />
                        </Column>
                        <ImageColumn>
                            <PhoneVideo video={rockvideo} />
                        </ImageColumn>
                    </Row>

                    <HorizontalScroll images={[rockscrollable]} />
                </RowWrapper>
                <RowWrapper id={'complex'}>
                    <Ellipse offset={40} image={complexellipseright1} top={0} right size={50} opacity={0.3} />
                    <Ellipse offset={30} image={complexellipseright2} top={50} right size={30} opacity={0.3} />
                    <Ellipse offset={40} image={complexellipseleft1} top={10} size={50} opacity={0.2} />
                    <Ellipse offset={50} image={complexellipseleft2} top={30} size={30} opacity={0.4} />
                    <Ellipse offset={40} image={complexellipseleft3} top={60} opacity={0.3} size={70} />
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>COMPLEX </CaseTitle>
                            <FeatureTags tags={['Android', 'IOS', 'Responsive Web App']} color={COMPANY_COLORS.complex} />
                            <ImageColumnMobile>
                                <PhoneVideo video={complexvideo} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                Complex app is a central point for the festival ticketing system, allowing users to scan and add their
                                physical tickets directly into the app. App offers a range of features such as interactive conference map,
                                event schedule, list of partners and exhibitors and many more. With Complex app we’ve enhanced the
                                conference experience while reducing clients operational costs.
                            </CaseDescription>
                            <Features color={COMPANY_COLORS.complex} />
                        </Column>
                        <ImageColumn>
                            <PhoneVideo video={complexvideo} />
                        </ImageColumn>
                    </Row>
                    <HorizontalScroll images={[complex1, complex2, complex3, complex4, complex5, complex6, complex7]} />
                </RowWrapper>
                <RowWrapper>
                    <BackgroundPrint />
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>CBDC</CaseTitle>
                            <FeatureTags tags={['Responsive Web App']} color={COMPANY_COLORS.cbdc} />
                            <ImageColumnMobile>
                                <BigImage src={cbdcmobile} alt={'CBDC mobile preview'} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                Safe and Efficient Central Bank Digital Currency (CBDC) Payments System that enables fast, cost-effective,
                                and secure transactions while ensuring broad participation in the payments ecosystem. The system supports
                                instant payment capability, interoperability, and ease of transfer across different forms of money, allowing
                                seamless integration with existing financial infrastructures. Ensuring identity sovereignty, users maintain
                                control over their personal data and transaction privacy. The solution is scalable, universally accessible,
                                compliant with regulatory frameworks, and adaptable to evolving societal and technological needs.
                            </CaseDescription>
                            <Features color={COMPANY_COLORS.cbdc} />
                        </Column>
                        <BigImageColumn>
                            <BigImage src={cbdc} alt={'CBDC preview'} />
                        </BigImageColumn>
                    </Row>
                </RowWrapper>
                <RowWrapper>
                    <BackgroundPrint />
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>OTP Banka</CaseTitle>
                            <FeatureTags tags={['Android', 'IOS', 'Responsive Web App']} color={COMPANY_COLORS.otp} />
                            <ImageColumnMobile>
                                <BigImage src={otpmobile} alt={'OTP mobile preview'} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                We led the complete rebranding of the bank's web portal and mobile applications, following the guidelines in
                                the OTP brand book. Along with optimizing the UX and improving the UI, we introduced several new
                                functionalities, including Apple Pay and Google Pay. We also streamlined the onboarding process for new
                                users as part of the merger of two banks into a single entity, among other enhancements.
                            </CaseDescription>
                            <Features color={COMPANY_COLORS.otp} />
                        </Column>
                        <BigImageColumn>
                            <BigImage src={otp} alt={'OTP preview'} />
                        </BigImageColumn>
                    </Row>
                </RowWrapper>
                <RowWrapper>
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>TAKK</CaseTitle>
                            <FeatureTags tags={['Responsive E-commerce']} color={COMPANY_COLORS.takk} />
                            <ImageColumnMobile>
                                <BigImage src={takk} alt={'Takk mobile preview'} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                When building TAKK the focus was on creating a user-friendly, fast, and effective e-commerce website,
                                suitable for both one time and regular subscription model purchases. Takk is supported in multiple
                                languages, and offers a variety of products for clients to choose from. By keeping the user interface
                                intuitive and mobile-friendly we created a platform which users can access and use throughout the day.
                            </CaseDescription>
                            <Features color={COMPANY_COLORS.takk} />
                        </Column>
                        <BigImageColumn>
                            <BigImage src={takk} alt={'Takk preview'} />
                        </BigImageColumn>
                    </Row>
                    <HorizontalScrollTitle sections={TAKK_SECTIONS} />
                </RowWrapper>
                <RowWrapper>
                    <BackgroundPrint />
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>Libraro</CaseTitle>
                            <FeatureTags tags={['Responsive Web App']} color={COMPANY_COLORS.libraro} />
                            <ImageColumnMobile>
                                <BigImage src={libraromobile} alt={'Libraro screens mobile'} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                Their approach leverages blockchain technology to create an online creative community that protects authors'
                                rights and helps them build a fan base. For publishers, the platform simplifies the discovery of original
                                literature with an existing audience. By focusing on transparency and security, they provide a space for
                                authors to safeguard their work and engage with readers, fostering collaboration. Their vision is to
                                revolutionize publishing by democratizing the discovery and distribution of literary content.
                            </CaseDescription>
                            <Features color={COMPANY_COLORS.libraro} />
                        </Column>
                        <BigImageColumn>
                            <BigImage src={libraro} alt={'Libraro preview'} />
                        </BigImageColumn>
                    </Row>
                </RowWrapper>
                <RowWrapper>
                    <BackgroundPrint />
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>Ecotrail</CaseTitle>
                            <FeatureTags tags={['Responsive Web App']} color={COMPANY_COLORS.cbdc} />
                            <ImageColumnMobile>
                                <TwoScreenImage src={ecotrail} alt={'Ecotrail image'} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                V sklopu javnega razpisa “DEMO PILOTI II 2018” je sofinanciran projekt “Sistem za učinkovito in
                                transparentno proizvodnjo”, “EcoTrail” v konzorcijskem konceptu. Namen izvedbe projekta je razviti in
                                demonstrirati standardizirano informacijsko rešitev za vzpostavitev just-in-time proizvodnega okolja (JiT
                                okolje) ter izkoristiti možnosti, ki jih nudijo blockchain tehnologija, IoT (Internet stvari) in
                                obvladovanje velikih količin podatkov. <br />
                                <br />
                                Naložbo sofinancira RS in EU iz Evropskega sklada za regionalni razvoj (www.eu-skladi.si).
                                <br />
                                <br />
                                Časovni okvir izvedbe: 3.1.2019 - 31.12.2021.
                            </CaseDescription>
                            <Sponsors>
                                <SponsorsRow>
                                    <SponsorImage src={ministrstvo} width={272} height={86} />
                                    <SponsorImage src={euRazvoj} width={231} height={98} />
                                </SponsorsRow>
                                <SponsorsRow>
                                    <SponsorsSingleItem>
                                        <SponsorImage src={spiritSlovenia} width={205} height={61} />
                                    </SponsorsSingleItem>
                                </SponsorsRow>
                            </Sponsors>
                        </Column>
                        <BigImageColumn>
                            <TwoScreenImage src={ecotrail} alt={'Ecotrail image'} />
                        </BigImageColumn>
                    </Row>
                </RowWrapper>
                <RowWrapper>
                    <BackgroundPrint />
                    <Row>
                        <Column>
                            <CaseType>Case study</CaseType>
                            <CaseTitle>LR system</CaseTitle>
                            <FeatureTags tags={['Responsive Web App']} color={COMPANY_COLORS.cbdc} />
                            <ImageColumnMobile>
                                <TwoScreenImage src={lrsystem} alt={'LR system image'} />
                            </ImageColumnMobile>
                            <CaseDescription>
                                V letu 2020 smo uspešno kandidirali na razpisu SPIRITa Slovenija, javna agencija "SPODBUDA TRAJNOSTNE
                                POSLOVNE STRATEŠKE TRANSFORMACIJE IN RAZVOJ NOVIH POSLOVNIH MODELOV V SLOVENSKIH PODJETJIH ZA LAŽJE
                                VKLJUČEVANJE V GLOBALNE VERIGE VREDNOSTI" - Sklop A in Sklop B, in sicer v Sklopu B z namenom izvedbe
                                projekta "Razvoj sistema za učenje (LR System)". <br /> <br /> Javni razpis je sofinanciran s pomočjo
                                <br /> <br />- Evropskega sklada za regionalni razvoj: www.eu-skladi.si, <br /> <br />- Ministrstva za
                                gospodarski razvoj in tehnologijo: www.mgrt.gov.si ter <br /> <br />- SPIRIT Slovenija, javne agencije:
                                www.spiritslovenia.si. Časovni okvir projekta: 1.4.2021 do 31.10.2021.
                            </CaseDescription>
                            <Sponsors>
                                <SponsorsRow>
                                    <SponsorImage src={ministrstvo} width={272} height={86} />
                                    <SponsorImage src={euRazvoj} width={231} height={98} />
                                </SponsorsRow>
                            </Sponsors>
                        </Column>
                        <BigImageColumn>
                            <TwoScreenImage src={lrsystem} alt={'LR system image'} />
                        </BigImageColumn>
                    </Row>
                </RowWrapper>
            </Wrapper>
            <YourFuture />
        </Layout>
    );
};
